<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title>
        <div class="ml-2">
          <router-link class="btn m-l-15 router-link-exact-active router-link-active btn-success" to="add-user"><i
              class="fas fa-plus mr-2"></i>
            Add New
          </router-link>
        </div>
      </bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="3">
              <h5 class="card-title">User List </h5>
            </b-col>
             <b-col lg="3">
              <b-form-group>
                <v-select placeholer="Select Role" v-model="selected_role" :options="role"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <v-select placeholer="Select Level" v-model="selected_status" :options="status"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{user.no}}</td>
                <td>{{user.usernamme}}</td>
                <td>{{user.fullname}}</td>
                <td>{{user.level_name}}</td>
                <td>{{user.email}}</td>
                <td><span class="badge badge-success">{{user.status}}</span></td>
                <td>
                  <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                  <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                  <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
        <b-card-footer>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev" next-text="Next">
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    data() {
      return {
        rows: 2,
        perPage: 1,
        currentPage: 1,
        users: [{
            no: 1,
            usernamme: 'admin_tb',
            fullname: 'Admin Teman Berlibur',
            email: 'admin_tb@gmail.com',
            level_name: 'Administrator',
            status: 'Active'
          },
          {
            no: 2,
            usernamme: 'm_fauzi',
            fullname: 'M Nur Fauzi',
            email: 'fauzi@Ggmail',
            level_name: 'Content Writter',
            status: 'Active'
          }
        ],
        selected: 'Y',
        options: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ],
        selected_role: 'Administrator',
        role: [
          'Administrator',
          'Content Writter',
          'Editor'
        ],
        selected_status: 'Active',
        status: [
          'Active',
          'Inactive',
        ]
      }
    }
  }
</script>