<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
    <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <h5 class="card-title">Add User Level</h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="12">
              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <label>Access Level Name<span class="text-danger mr5">*</span></label>
                    <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Administrator" :formatter="formatter">
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col lg="6">
                  <b-form-group>
                  <label>Level<span class="text-danger mr5">*</span></label>
                  <v-select multiple :options="['Home ','Article Category','Services ','Article List','Our Works']" />
                  </b-form-group>
                </b-col>

                <b-col lg="6">
                  <b-form-group class="mt-3">
                    <label>Status<span class="text-danger mr5">*</span></label>
                    <b-form-radio-group v-model="selected" :options="options" class="mb-3" value-field="item"
                      text-field="name" disabled-field="notEnabled"></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-row>
            <b-col lg="12" class="text-right">
              <button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
              <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import GlobalVue from '../libs/Global.vue'
import BoPageTitle from '@/components/BoPageTitle.vue'

export default {
  extends: GlobalVue,
  components: {
    BoPageTitle,
  },
  data() {
    return {
      selected: 'Y',
      options: [
        { item: 'Y', name: 'Active' },
        { item: 'N', name: 'Inactive' }
      ]
    }
  }
}
</script>