<template>
	<div class="page-wrapper">
		<b-container fluid>
			<bo-page-title></bo-page-title>
			<bo-card title="Clients List" v-if="!this.$route.params.slug">
				<template #filters>
					<b-row align-h="end">
						<b-col lg="3">
							<b-form-group>
								<v-select placeholer="Select Status" v-model="selected_status" :options="status"></v-select>
							</b-form-group>
						</b-col>
						<b-col lg="3">
							<b-form-group>
								<v-select placeholer="Select Category" v-model="selected_category" :options="category">
								</v-select>
							</b-form-group>
						</b-col>
						<b-col lg="4">
							<b-input-group>
								<b-form-input placeholder="Type keyword then enter..."></b-form-input>
								<b-input-group-append>
									<b-button variant="success"><i class="fas fa-search"></i></b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
					</b-row>
				</template>
				<b-row>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-danger mt-2 mr-1">Inactive</span>
							</div>
						</article>
					</b-col>
					<b-col lg="3">
						<article class="card_list">
							<div class="wrap_thumb_list">
								<img src="http://placehold.it/301x200" alt="" title="" />
								<div class="bulle_cta">
									<a class="bullet_edit mr-2" href=""><i class="fas fa-pencil-alt"></i></a>
									<a class="bullet_delete" href=""><i class="far fa-trash-alt"></i></a>
								</div>
							</div>
							<div class="wrap_post_content">
								<span class="label label-success mt-2 mr-1">Active</span>
							</div>
						</article>
					</b-col>
				</b-row>
				<template #footer>
					<b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev" next-text="Next">
					</b-pagination>
				</template>
			</bo-card>
			<b-tabs v-else>
				<b-tab title="Articles">
					<b-card no-body>
						<b-card-body>
							<b-row>
								<b-col lg="8">
									<b-row>
										<b-col lg="12">
											<b-form-group>
												<label>Client Name<span class="text-danger mr5">*</span></label>
												<b-form-input id="input-formatter" v-model="text1" placeholder="e.g PT Maju Raya Abadi"
													:formatter="formatter">
												</b-form-input>
											</b-form-group>
										</b-col>

										<b-col lg="12">
											<b-form-group>
												<label>Website Link<span class="text-danger mr5">*</span></label>
												<b-form-input id="input-formatter" v-model="text1" placeholder="e.g https://www.majurayabadi.com"
													:formatter="formatter">
												</b-form-input>
											</b-form-group>
										</b-col>

										<b-col lg="6">
											<b-form-group class="mt-2">
												<label>Status <span class="text-danger mr5">*</span></label>
												<b-form-radio-group v-model="selected" :options="options" class="mb-3" value-field="item"
													text-field="name" disabled-field="notEnabled">
												</b-form-radio-group>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col lg="4">
									<b-row>
										<b-col lg="12" class="mb-2">
											<div class="file-uploader">
												<label>Main Image <span class="text-danger mr5">*</span></label>
												<b-alert show variant="info" class="text-center my-2">
													Min. Image Dimension: 1500x900<br />
													File Formats: .png, .jpg, .svg<br />
													Max. File Size: 2MB
												</b-alert>
												<div class="file-uploader-cta">
													<label for="fileUploader" class="btn btn-uploader btn-block">
														<i class="icon-picture" /> Upload
													</label>
													<b-form-file plain id="fileUploader" />
												</div>
											</div>
										</b-col>
										<b-col lg="12" class="mb-2">
											<b-form-group>
												<label>Alt Image<span class="text-danger mr5">*</span></label>
												<b-form-input id="input-formatter" v-model="text1" placeholder="e.g Image title"
													:formatter="formatter">
												</b-form-input>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
						</b-card-body>
						<b-card-footer>
							<b-row>
								<b-col lg="12" class="text-right">
									<button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
									<button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
								</b-col>
							</b-row>
						</b-card-footer>
					</b-card>
				</b-tab>

				<b-tab title="SEO Settings">
					<b-card no-body>
						<b-form @submit.prevent="submitMethod">
							<b-card-body>
								<b-form-group label-for="homeMetaTitle">
									<template #label>
										Meta Title
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
								</b-form-group>
								<b-form-group label-for="homeMetaDesc">
									<template #label>
										Meta Description
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-textarea id="homeMetaDesc" placeholder="Meta Description" v-model="seoRow.description" />
								</b-form-group>
								<b-form-group label-for="homeMetaTags">
									<template #label>
										Meta Tags
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
										tag-variant="success" tag-class="text-white" v-model="seoRow.keywords" />
								</b-form-group>
							</b-card-body>
							<b-card-footer>
								<div class="text-right">
									<b-button class="btn-rounded" type="submit" variant="success">Submit</b-button>
								</div>
							</b-card-footer>
						</b-form>
					</b-card>
				</b-tab>
			</b-tabs>
		</b-container>
	</div>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
	
	export default {
		name: 'Article',
		extends: GlobalVue,
		components: {},

		mounted() {
			this.loadSeoData()
		},
		computed: {
			dataSrc() {
				return this.this.dummyData.home
			},
		},
		methods: {
			loadSeoData() {
				let datas = this.dataSrc.seo
				this.seoRow = datas
			},
		},
		data() {
			return {
				seoRow: {},
				rows: 30,
				perPage: 1,
				currentPage: 5,
				value: '',
				value2: '',
				users: [{
						no: 1,
						fullname: 'Super Admin',
						activity: 'BO-OPEN-MENU-AUDIT-TRAIL',
						date: '02 August 2021, 11:01'
					},
					{
						no: 2,
						fullname: 'Super Admin',
						activity: 'BO-OPEN-MENU-ADD-USER-LEVEL',
						date: '02 August 2021, 11:01'
					},
					{
						no: 3,
						fullname: 'Super Admin',
						activity: 'BO-OPEN-MENU-USER-LEVEL',
						date: '02 August 2021, 11:01'
					}
				],
				selected_status: 'Active',
				status: [
					'Active',
					'Inactive',
				],

				selected_category: 'category 1',
				category: [
					'category 1',
					'category 2',
				],

				selected_options: 'Publish',
				options: [
					'Draft',
					'Publish',
				],
			}
		}
	}
</script>